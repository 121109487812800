<template>
  <v-layout row wrap class="pl-md-5 pr-md-5 pl-xl-10 pr-xl-10">
    <v-col cols="6" sm="4" md="3" xl="3" v-for="item in families" :key="item.id">
      <v-card hover @click="goTo(item)" :class="'catalog-group-card no-card-image'">
        <v-card-title primary-title>
          <div :class="'headline primary--text'">
            {{ item.data.FamilyDescription }}
          </div>
        </v-card-title>
      </v-card>
    </v-col>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "Catalog",
  data: () => {
    return {
      families: [],
      subfamilies: [],
      currentOffset: 0,
      limit: 30,
    };
  },
  /**
   * Before accessing the Sales' section, it is necessary to verify if there is any open Cash Drawer.
   * If there's not, then the User is redirected to the interface where Cash Drawers are open
   *
   * @modified ana.castro 2021-08-12 2128
   * */
  async beforeCreate() {
    if (this.$route.params.validateCashDrawer) {
      let isAnyCashDrawerOpen = 0;
      let isAnyCashDrawerDefault = 0;
      isAnyCashDrawerOpen = await this.$store.dispatch("cashDrawers/isAnyCashDrawerOpen");
      if (!isAnyCashDrawerOpen) {
        await this.$router.push({ name: "cash-drawers-management-redirect" });
      } else {
        isAnyCashDrawerDefault = await this.$store.dispatch("cashDrawers/getDefaultCashDrawers");
        if (isAnyCashDrawerDefault.length === 0) {
          await this.$router.push({ name: "cash-drawers-management-redirect" });
        }
      }
    }
  },
  methods: {
    /**
     * This method defines the behaviour of clicking on a Family/Subfamily.
     * 1 - if clicking on a Family with Subfamilies:
     * 1.1 - if there's a unique subfamily, the user is redirected to the list of Items belonging to a subfamily;
     * 1.2 - if there's more then a subfamily, the user is redirected to the list of subfamilies belonging to the family;
     * 2 - if clicking on a Family with no Subfamilies, the user is redirected to the list of Items belonging to a subfamily.     *
     *
     * @param item - The Family/Sub Family which was clicked
     */
    goTo: function (item) {
      if (item.subFamilies) {
        let code = item.code;
        let offset = 0;
        let limit = 2;
        this.$store.dispatch("families/getChildren", { code, offset, limit }).then((result) => {
          if (result.length === 1) {
            this.$store.dispatch("families/setSelectedFamily", item.id);
            this.$router.push({ name: "catalog-detail-items", params: { detail: result[0].id } }).catch(() => true);
          } else {
            this.$router
              .push({ name: "catalog-detail", params: { id: item.id, zone: this.cartZone.code } })
              .catch(() => true);
          }
        });
      } else {
        this.$router.push({
          name: "catalog-detail-items",
          params: { detail: item.id },
        });
      }
    },

    /**
     * This method is triggered when entering this view.
     * It aims at identifying if the information to display on the Group's Detail is:
     * 1 - the Items of the Group;
     * 2 - other Groups.
     *
     * (1) First, this method verifies if this View is being used "generically" (corresponds to the route name: "catalog")
     * (2) or if it is used to display the information of a family (corresponds to the route name: "catalog-detail")
     * @param pk
     */
    getCatalogEntryInfo(pk) {
      this.catalogEntryIdentifier = parseInt(pk);
      //(1) First, this method verifies if this View is being used "generically" (corresponds to the route name: "catalog")
      if (!this.catalogEntryIdentifier) {
        this.$store.dispatch("families/getMain").then((result) => {
          this.families = result;
          this.$store.dispatch("loader/setLoading", false);
        });
      }
      //(2) or if it is used to display the information of a family (corresponds to the route name: "catalog-detail")
      else {
        this.$store
          .dispatch("families/get", this.catalogEntryIdentifier)
          .then((result) => {
            let code = result[0].code;
            let offset = this.currentOffset;
            let limit = this.limit;

            this.$store
              .dispatch("families/getChildren", {
                code,
                offset,
                limit,
              })
              .then((result) => {
                if (result.length > 0) {
                  this.subfamilies = this.subfamilies.concat(result);
                  this.families = this.subfamilies;
                  this.vsheetSelectedFamilyPosition = this.families.findIndex(
                    (family) => family.id === this.selectedFamilyId
                  );
                  this.currentOffset += result.length;
                }
                this.$store.dispatch("loader/setLoading", false);
              })
              .catch((error) => {
                Vue.$log.debug(error.message);
                this.$store.dispatch("loader/setLoading", false);
              });
          })
          .catch((error) => {
            Vue.$log.debug(error.message);
            this.$store.dispatch("loader/setLoading", false);
          });
      }
    },
  },
  computed: {
    /**
     * This function defines the content of the Menu.
     * @returns {[]}
     */
    getMenuItems: function () {
      return this.menuItems;
    },

    ...mapGetters({
      baseUrl: "configs/apiImgBaseUrl",
      cartZone: "cart/cartZone",
    }),
  },
  watch: {
    /**
     * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
     * To react to params changes in the same component, it is necessary to watch the $route object
     * @param to
     * @param from
     */
    $route(to, from) {
      var toGroupIdentifier = to.params.id;
      var fromGroupIdentifier = from.params.id;
      if (!fromGroupIdentifier || toGroupIdentifier !== fromGroupIdentifier) {
        this.subfamilies = [];
        this.currentOffset = 0;
        this.getCatalogEntryInfo(toGroupIdentifier);
        this.$store.dispatch("families/setSelectedFamily", toGroupIdentifier);
      }
      var toName = to.name;
      var fromName = from.name;
      //@modified ana.castro 2022.12.05 SAFO-47
      // if (toName !== fromName && to.params.salePriceRange) {
      //   this.$store.dispatch("cart/setCartSalePriceRange", this.$route.params.salePriceRange);
      // }
      if (toName !== fromName || to.params.zone || to.params.zone === 0) {
        // this.$store.dispatch("cart/setCartSalePriceRange", this.$route.params.salePriceRange);
        this.$store.dispatch("cart/setCartZone", this.$route.params.zone);
      }
    },
  },
  mounted() {
    this.getCatalogEntryInfo(this.$route.params.id);

    window.onscroll = () => {
      if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
        // Try to find more results and put loading layer only if the number of
        // results is multiple of the limit
        if (this.families.length % this.limit === 0) {
          this.$store.dispatch("loader/setLoading", true);
          this.getCatalogEntryInfo(this.$route.params.id);
        }
      }
    };

    //Some points of the application redirect the User to the Catalog interface.
    //  And in some of those points,
    //  it is necessary to indicate that the User must select the Salesman assigned to the Sale.
    //  (which means: it is necessary to display the Dialog defined on the AppLayout which displays the available Salesmen)
    if (this.$route.params.show) {
      this.$store.dispatch("salesmen/setDisplaySelectSalesman", { status: true });
    }

    //@modified ana.castro 2022.12.05 SAFO-47
    // if (this.$route.params.salePriceRange) {
    //   this.$store.dispatch("cart/setCartSalePriceRange", this.$route.params.salePriceRange);
    // }
    if (this.$route.params.zone === 0 || this.$route.params.zone) {
      this.$store.dispatch("cart/setCartZone", this.$route.params.zone);
    }
  },
};
</script>

<style scoped></style>
